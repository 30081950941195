export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
  babyRed: "#ff9494", // Lighter
  red: "#ff2929", // Darker
  black: "#1f1f1f",
  white: "#ffffff",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.babyRed}, ${
  COLORS.red
})`

export const BORDER_RADIUS = "10px"
